import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Breadcrumb, Tag } from "antd";
import {
  BlogBreadCrumbContainer,
  BlogPostContainer,
  BlogPostHeader,
  BlogPostHeaderImage,
  BlogPostTagsContainer,
  // BlogPostMetaContainer,
  BlogPostContent
} from "./blogPost.style";

export const BlogPost = ({ markdownRemark }) => {
  const { frontmatter, html } = markdownRemark;

  const headerImage = (header) => {
    if (!header) {
      return null;
    }

    const image = getImage(header);
    image.layout = 'fullWidth';

    return (
      <BlogPostHeaderImage maxWidth={1300} pb={8}>
        <GatsbyImage image={image} />
      </BlogPostHeaderImage>
    )
  }

  return (
    <>
      <BlogBreadCrumbContainer mt={116} ml={8} mr={8} mb={4}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/">Home</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/blog">Blog</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{frontmatter.title}</Breadcrumb.Item>
        </Breadcrumb>
      </BlogBreadCrumbContainer>
      <BlogPostContainer fontSize={20} ml={8} mr={8}>
        <BlogPostHeader padding={4}>
          {frontmatter.title}
        </BlogPostHeader>
        {/* <BlogPostMetaContainer margin={2}>
          {frontmatter.date}
        </BlogPostMetaContainer> */}
        <BlogPostTagsContainer margin={2}>
          {frontmatter.tags.map(tag => {
            return (
              <Tag color="red" style={{ fontSize: '18px' }}>{tag}</Tag>
            )
          })}
        </BlogPostTagsContainer>
        <BlogPostContent padding={8}>
          {headerImage(frontmatter.header)}
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </BlogPostContent>
      </BlogPostContainer>
    </>
  )
}
