import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import { BlogPost } from '../components/common/blog/BlogPost'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

import 'antd/dist/antd.css'

export default function Template({ data }) {
  const { markdownRemark } = data

  return (
    <ThemeProvider theme={raveniiTheme}>
      <>
        <Seo title={`RAVENii | ${markdownRemark.frontmatter.title}`} />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <BlogPost markdownRemark={markdownRemark} />

          <ContactUsSection />

          <Footer />
        </ContentWrapper>
      </>
    </ThemeProvider>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featured
        tags
        header {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`

// @TODO: leverage this next and previous blog post functionality
// for example:
//       <nav className="blog-post-nav">
//         <ul
//           style={{
//             display: `flex`,
//             flexWrap: `wrap`,
//             justifyContent: `space-between`,
//             listStyle: `none`,
//             padding: 0,
//           }}
//         >
//           <li>
//             {previous && (
//               <Link to={previous.fields.slug} rel="prev">
//                 ← {previous.frontmatter.title}
//               </Link>
//             )}
//           </li>
//           <li>
//             {next && (
//               <Link to={next.fields.slug} rel="next">
//                 {next.frontmatter.title} →
//               </Link>
//             )}
//           </li>
//         </ul>
//       </nav>
