import styled from 'styled-components'
import { fontSize, minWidth, maxWidth, space } from 'styled-system'
import BannerBG from '../../../../assets/image/banner-texture.png'

export const BlogBreadCrumbContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  ${space}
`

export const BlogPostContainer = styled.div`
  border-radius: 0.19571429rem;
  box-shadow: 0 1px 1px 1px #d4d4d5, 0 0 0 1px #d4d4d5;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  ${fontSize}
  ${space}
  ${minWidth}
  ${maxWidth}
`

export const BlogPostHeader = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.025em;
  background-image: url(${BannerBG}), linear-gradient(45deg, rgba(99, 23, 25, 0.8) 0%, rgba(176, 58, 62, 0.7) 75%);
  ${space}
`

export const BlogPostHeaderImage = styled.div`
  ${space}
  ${maxWidth}
  display: block;
  margin: 0 auto;
`

export const BlogPostContent = styled.div`
  li {
    margin-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
  }
  blockquote {
    margin: 18px;
  }
  ${space}
`

export const BlogPostTagsContainer = styled.div`
  ${space}
  ${fontSize}
`

export const BlogPostMetaContainer = styled.div`
  font-style: italic;
  ${space}
`
